<template>
  <div class="game-container">
    <SeesawGame
      :chooseHanziList="chooseHanziList"
      :gameInfo="gameInfo"
      :starNum="3"
      :stepsImgList="stepsImgList"
      :answerIdList="[141, 25, 36]"
    />
  </div>
</template>

<script>
import SeesawGame from "@/components/Course/GamePage/SeesawGame";
export default {
  data() {
    return {
      gameInfo: {
        seasawBottomImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/base-ge.svg`),
        baseImg: require(`@/assets/img/16-GAMES/G34-scales/base-scales.svg`),
      },
      stepsImgList: [
        {
          id: 1, // yi
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi.svg`),
        },
        {
          id: 2,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san.svg`),
        },
        {
          id: 3,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-si.svg`),
        },
        {
          id: 41,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege.svg`),
        },
        {
          id: 42,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege.svg`),
        },
        {
          id: 5, // 
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-meimei.svg`),
        },
        {
          id: 6,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-laoshi.svg`),
        },
        {
          id: 7,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-baba.svg`),
        },
        {
          id: 12,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi-san.svg`),
        },
        {
          id: 141,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi-gege.svg`),
        },
        {
          id: 21,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-yi.svg`),
        },
        {
          id: 241,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-gege.svg`),
        },
        {
          id: 411,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege-yi.svg`),
        },
        {
          id: 412,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege-san.svg`),
        },


        {
          id: 242,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-san-gege.svg`),
        },
        {
          id: 25,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-san-meimei.svg`),
        },
        {
          id: 422,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-gege-san.svg`),
        },
        {
          id: 425,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-gege-meimei.svg`),
        },
        {
          id: 52,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-meimei-san.svg`),
        },
        {
          id: 542,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-meimei-gege.svg`),
        },


                {
          id: 63,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-laoshi-si.svg`),
        },
        {
          id: 67,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-laoshi-baba.svg`),
        },
        {
          id: 36,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-si-laoshi.svg`),
        },
        {
          id: 37,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-si-baba.svg`),
        },
        {
          id: 76,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-baba-laoshi.svg`),
        },
        {
          id: 73,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-baba-si.svg`),
        },
      ],
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/lesson-12-story-1.svg`),
          wordsPinyin: "yí ge gēge",
          wordsHanzi: "一个哥哥",
          hanziChooseList: [
            {
              pinyin: "yī",
              hanzi: "一",
              id: 1,
            },
            {
              pinyin: "gēge",
              hanzi: "哥哥",
              id: 41,
            },

            {
              pinyin: "sān",
              hanzi: "三",
              id: 2,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/lesson-12-story-2.svg`),
          wordsPinyin: "sān ge mèimei",
          wordsHanzi: "三个妹妹",
          hanziChooseList: [
            {
              pinyin: "sān",
              hanzi: "三",
              id: 2,
            },
            {
              pinyin: "mèimei",
              hanzi: "妹妹",
              id: 5,
            },
            {
              pinyin: "gēge",
              hanzi: "哥哥",
              id: 42,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/lesson-12-story-3.svg`),
          wordsPinyin: "sān ge lǎoshī",
          wordsHanzi: "四个老师",

          hanziChooseList: [
            {
              pinyin: "lǎoshī",
              hanzi: "老师",
              id: 6,
            },
            {
              pinyin: "sì",
              hanzi: "四",
              id: 3,
            },
            {
              pinyin: "bàba",
              hanzi: "爸爸",
              id: 7,
            },
          ],
        },
      ],
    };
  },
  components: {
    SeesawGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>