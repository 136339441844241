<template>
  <div class="game-container">
    <ChoosePicBySentenceGame
      :bgImg="bgImg"
      :picList="picList"
      :sentenceList="sentenceList"
    />
  </div>
</template>

<script>
import ChoosePicBySentenceGame from "@/components/Course/GamePage/ChoosePicBySentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/background-board.svg"),
      picList: [
        {
          id: 1,
          menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-1.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-1-bw.svg"),
        },
        {
          id: 2,
          menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-2.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-2-bw.svg"),
        },
        {
          id: 3,
          menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-3.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-3-bw.svg"),
        },
        {
          id: 4,
          menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-4.svg"),
          menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-4-bw.svg"),
        },
      ],

      sentenceList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-1.svg"),
          // hanzi: "我有一个妹妹",
          // pinyin: "Wǒ yǒu yí ge mèimei",
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-2.svg"),
          // hanzi: "我有五个妹妹",
          // pinyin: "Wǒ yǒu wǔ ge mèimei",
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-3.svg"),
          // hanzi: "我有三个哥哥",
          // pinyin: "Wǒ yǒu sān ge gēge",
        },
        {
          id: 4,
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-4.svg"),
          // hanzi: "我有一个妹妹",
          // pinyin: "Wǒ yǒu yí ge mèimei",
        },
      ],
    };
  },
  components: {
    ChoosePicBySentenceGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











