var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("SeesawGame", {
        attrs: {
          chooseHanziList: _vm.chooseHanziList,
          gameInfo: _vm.gameInfo,
          starNum: 3,
          stepsImgList: _vm.stepsImgList,
          answerIdList: [141, 25, 36],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }