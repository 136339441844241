<template>
  <div class="write-page-heng-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImageObj"
      :strokeImgFill="strokeImgFill"
      :strokePinyin="strokePinyin"
      :lesson="lesson"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "héngzhégōu",
      strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-12-hengzhegou-grey.svg"),
      strokeImgFill: require("@/assets/img/15-Hanzi-strokes/lesson-12-hengzhegou-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-hengzhegou/water-1.svg"),
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-hengzhegou/water-2.svg"),
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-hengzhegou/water-3.svg"),
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-hengzhegou/water-4.svg"),
        },
      ],
      flagImageObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-hengzhegou/water-pending.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-hengzhegou/water-complete.svg"),
        pinyin: "héngzhégōu",
        tipImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-12-stroke-hengzhegou-red.gif"),
      },
    };
  },
  props: {
    lesson: {
      type: Number,
      require: true,
    },
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
